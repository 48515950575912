import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'lib/utils';

const buttonVariants = cva(
  'cursor-pointer flex items-center justify-center font-semibold whitespace-nowrap rounded-full text-inherit font-semibold disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'bg-purple-blue text-white rounded-lg hover:bg-purple-blue/90 disabled:bg-[#B5BDFA] cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        secondary:
          'bg-white text-dark-blue border border-[#E1E3E4] rounded-lg disabled:cursor-not-allowed hover:bg-old-gray cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        white:
          'bg-white text-transparent w-full text-sm hover:text-white hover:bg-transparent border border-white text-purple-blue cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        destructive:
          'text-white bg-red border border-bg-red-600 hover:bg-red-400 cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        gray: 'bg-[#E6E6F1] hover:bg-[#E6E6F1]/60 text-dark-blue border border-[#E1E3E4] rounded-lg disabled:cursor-not-allowed cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',


        default:
          'bg-primary text-primary-foreground hover:bg-primary/90 cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        ghost:
          'hover:bg-accent hover:text-accent-foreground cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        link: 'text-purple-blue underline-offset-4 hover:underline !px-0 !py-0 !h-auto !font-normal cursor-pointer inline-flex items-center font-poppins justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',

        // new theme button variants
        primaryBrand:
          'bg-brand-500 hover:bg-brand-700 border border-2 border-brand-500 !text-white hover:border-brand-700 disabled:border-[1px] disabled:border-gray-200 disabled:bg-gray-100 disabled:!text-gray-300 outline-none',
        darkBrand:
          'bg-brand-950 hover:bg-brand-900 border border-2 border-brand-950 !text-white hover:border-brand-900 disabled:border-[1px] disabled:border-gray-950 disabled:bg-gray-950 disabled:!text-gray-600 outline-none',
        nakedGray:
          'bg-transparent hover:bg-gray-100 border border-2 border-gray-200 !text-gray-700 hover:!text-gray-950 hover:border-gray-200 disabled:border-gray-100 disabled:bg-transparent disabled:!text-gray-300 outline-none',
        nakedBrand:
          'bg-transparent hover:bg-brand-100 border border-2 border-transparent !text-brand-500 hover:!text-brand-700 hover:border-brand-100 disabled:border-transparent disabled:bg-transparent disabled:!text-gray-300 outline-none',
        nakedGrayLink:
          'bg-transparent hover:bg-transparent border border-2 border-transparent !text-gray-600 hover:!text-gray-950 hover:border-transparent disabled:border-transparent disabled:bg-transparent disabled:!text-gray-300 outline-none',
        nakedBrandLink:
          'bg-transparent hover:bg-transparent border border-2 border-transparent !text-brand-500 hover:!text-brand-700 hover:border-transparent disabled:border-transparent disabled:bg-transparent disabled:!text-gray-300 outline-none',
        tertiaryGray:
          'rounded-full bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-950 disabled:text-gray-300 outline-none',
        black: 'bg-black hover:bg-black/90 text-white disabled:pointer-events-none disabled:opacity-50 outline-none',
        green:
          'bg-success-500 hover:bg-success-600 border border-2 border-success-500 !text-white hover:border-success-600 disabled:border-[1px] disabled:border-gray-200 disabled:bg-gray-100 disabled:!text-gray-300 outline-none',
        blue: 'bg-blue-500 hover:bg-blue-600 border border-2 border-blue-500 !text-white hover:border-blue-600 disabled:border-[1px] disabled:border-gray-200 disabled:bg-gray-100 disabled:!text-gray-300 outline-none',
        orange:
          'bg-warning-500 hover:bg-warning-600 border border-2 border-warning-500 !text-white hover:border-warning-600 disabled:border-[1px] disabled:border-gray-200 disabled:bg-gray-100 disabled:!text-gray-300 outline-none',
        approve: 'bg-transparent !text-success-500 border-0 hover:bg-transparent hover:!text-success-700 disabled:!text-gray-300 outline-none',
        decline: 'bg-transparent !text-error-500 border-0 hover:bg-transparent hover:!text-error-700 disabled:!text-gray-300 outline-none',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
        icon: 'h-10 w-10',
        xs: 'h-[30px]  text-xs px-2 py-1 font-normal',

        // new theme button sizes

        'x-small': 'gap-1 py-[6px] px-3 text-small disabled:py-[7px]',
        small: 'gap-1 py-2 px-[14px] text-small disabled:py-[9px]',
        medium: 'gap-[6px] py-2 px-4 text-medium disabled:py-[9px]',
        large: 'gap-[6px] py-[10px] px-[18px] text-medium disabled:py-[11px]',
        'x-large':
          'gap-[10px] py-[14px] px-[22px] text-large disabled:py-[15px]'
      },
      colors: {
        color: {
          red: 'bg-[#cc0000] text-white'
        }
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
