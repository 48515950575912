import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import Cookies from 'js-cookie';

import { CurrentCommunityType } from 'types';
import { TELEGRAM_SUBDOMAIN_SUFFIX } from 'components/telegram';

type CommunityContextType = {
  community: CurrentCommunityType | null;
  chooseCommunity: (community?: CurrentCommunityType, domain?: string) => void;
};

export const CommunityContext = createContext({
  community: null,
  chooseCommunity: () => {},
} as CommunityContextType);


export const GENERAL_SUBDOMAIN = 'app';
export const COMMUNITY_SWITCHER_COOKIE = 'community-switcher-sbdmn';

const STOP_SUBDOMAINS = ['www'];

export const CommunityContextProvider = ({ children }: { children: ReactNode }) => {
  const params = useSearchParams();
  const [chosenCommunity, setChoseCommunity] = useState<CurrentCommunityType | null>(null);

  const community = useMemo(() => {
    {/* READ FROM STATE */}
    if (chosenCommunity !== null) return chosenCommunity;

    if (typeof window !== 'undefined') {
      {/* READ FROM COOKIE */}
      const cookieCommunity = Cookies.get(COMMUNITY_SWITCHER_COOKIE);
      if (cookieCommunity && cookieCommunity !== '') {
        return {
          name: cookieCommunity,
          subdomain: cookieCommunity
        } as CurrentCommunityType;
      }

      {/* READ FROM SUBDOMAIN */}
      let subdomain = window.location.hostname.split('.')[0];
      if (STOP_SUBDOMAINS.includes(subdomain)) return null;

      if (subdomain && subdomain.endsWith(TELEGRAM_SUBDOMAIN_SUFFIX)) {
        subdomain = subdomain.replace(TELEGRAM_SUBDOMAIN_SUFFIX, '');
      }

      if (subdomain === GENERAL_SUBDOMAIN) {
        subdomain = params?.get('community') || '';
      }
      return {
        name: subdomain,
        subdomain: subdomain
      } as CurrentCommunityType;
    }
    return null;
  }, [chosenCommunity, params]);

  const chooseCommunity = (community?: CurrentCommunityType, domain?: string) => {
    setChoseCommunity(community || null);
    const newDomain = domain ? ` domain=${domain};` : '';
    document.cookie = `${COMMUNITY_SWITCHER_COOKIE}=${community?.subdomain || ''}; path=/;${newDomain}`;
  };

  return (
    <CommunityContext.Provider value={{
      community: community,
      chooseCommunity: chooseCommunity
    }}>
      {children}
    </CommunityContext.Provider>
  );
};

export function useCommunity() {
  return useContext(CommunityContext);
}
